<script lang="ts" setup>
import type { TranslateResult } from "vue-i18n";
import { type RouteLocationRaw } from "vue-router";
const appConfig = useAppConfig();

enum ButtonSize {
  Small = "small",
  Large = "large",
  Dense = "dense",
}

const props = withDefaults(
  defineProps<{
    title?: string | TranslateResult;
    to?: RouteLocationRaw;
    icon?: string[] | null | "";
    underline?: boolean;
    fullPadding?: boolean;
    halfPadding?: boolean;
    textColor?: string;
    iconClass?: {
      [key: string]: boolean;
    };
    full?: boolean;
    height?: string;
    background?: string;
    size?: ButtonSize | string;
    externalLink?: boolean;
  }>(),
  { to: "", title: "", size: ButtonSize.Small, textColor: "" },
);
const computedIconClass = computed(() => {
  return props.iconClass || {};
});

const computedHeight = computed(() => {
  if (props.size === ButtonSize.Large) {
    return "var(--button-spacer-y-l)";
  }
  if (props.size === ButtonSize.Small) {
    return "var(--button-spacer-y-s)";
  }
  if (props.size === ButtonSize.Dense) {
    return "3.5rem";
  }
  return undefined;
});

const computedTextColor = computed(() => {
  if (!props.background || props.background === "primary") {
    return appConfig.textColorPrimaryBackground;
  }

  // Funktion, um die Luminanz eines Hex-Farbwerts zu berechnen
  const getLuminance = (hex: string) => {
    const r = parseInt(hex.substr(1, 2), 16);
    const g = parseInt(hex.substr(3, 2), 16);
    const b = parseInt(hex.substr(5, 2), 16);
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  // Berechne die Luminanz der Hintergrundfarbe
  const luminance = getLuminance(props.background);

  // Wähle die Textfarbe basierend auf der Luminanz
  return luminance < 150 ? "255, 255, 255" : "0, 0, 0"; // Weiß oder Schwarz
});
</script>

<template>
  <v-btn
    :to="to"
    class="hw-btn-base"
    :variant="full || background ? 'flat' : 'text'"
    :height="computedHeight"
    :block="full"
    :color="background"
    :class="[
      { 'hw-btn-base--underline': underline },
      { 'hw-btn-base--half-padding': halfPadding },
      { 'hw-btn-base--full-padding': fullPadding },
    ]"
    :style="
      background
        ? {
            '--hw-btn-color': computedTextColor,
            '--hw-btn-color--hover': computedTextColor,
            color: `rgb(${computedTextColor})`,
          }
        : {}
    "
    @dragstart.prevent
  >
    <div
      class="hw-btn-base-content d-flex justify-content-between align-center"
    >
      <span class="hw-btn-base-text">{{ title }}</span>
      <slot />

      <v-icon
        v-if="icon"
        :icon="icon"
        :class="[
          'hw-btn-base-icon',
          computedIconClass,
          {
            'icon--external-link': externalLink,
          },
        ]"
      />
      <div v-else-if="$slots.icon" class="hw-btn-base-icon d-flex">
        <slot name="icon"> </slot>
      </div>
    </div>
  </v-btn>
</template>

<style lang="scss">
.hw-btn-base {
  --hw-btn-color: inherit;
  --v-hover-opacity: 0;
  --v-activated-opacity: 0;
  --hw-btn-color--hover: var(--v-theme-primary);
  /** disable user drag */
  -webkit-user-drag: none;
  user-drag: none;
  /** disable user select */
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;

  .v-btn__content {
    position: relative;
    white-space: normal;
    word-wrap: break-word;
    color: rgb(var(--hw-btn-color));
  }

  &:hover .v-btn__content {
    color: rgb(var(--hw-btn-color--hover));
  }
}

.hw-btn-base-text--black {
  --hw-btn-color--hover: 0, 0, 0;
  color: var(--hw-btn-color--hover) !important;
}

.hw-btn-base--underline {
  --v-hover-opacity: 0;
  --v-activated-opacity: 0;
  .v-btn__content {
    position: relative;
  }
  & .v-btn__content::before {
    content: "";
    position: absolute;
    display: block;
    height: 4px;
    bottom: -0.5rem;
    left: 0;
    background-color: rgb(var(--hw-btn-color--hover));
    width: 0;
    transition: all 0.3s ease-in-out;
  }

  &:hover .v-btn__content::before {
    width: 100%;
  }

  .v-btn--active .v-btn__content::before {
    width: 100%;
  }
}

.hw-btn-base--underline.v-btn--active .v-btn__content::before {
  width: 100%;
}

.hw-btn-base--half-padding {
  padding-inline: calc(0.5 * var(--spacer)) !important;
}

.hw-btn-base--full-padding {
  padding-inline: var(--spacer) !important;
}

.hw-btn-base-icon {
  margin-left: var(--button-spacer-x-l);
}

.v-btn--disabled {
  opacity: 0.4 !important;
}

.v-btn--disabled.v-btn--variant-elevated .v-btn__overlay,
.v-btn--disabled.v-btn--variant-flat .v-btn__overlay {
  opacity: 0;
}
</style>
